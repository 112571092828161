import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

export interface IRoom {
  id: number;
  roomType: {
    id: number;
    group: string;
    detail: string;
  };
  roomNo: string;
  costCenter: string;
  orgaID: number | null;
  orgaTxt: string | null;
  area: string;
  maxCapacity: number;
  floorId: number;
  building: IBuilding;
  buildingId: number;
  floor: IFloor;
}

export interface IFloor {
  id: number;
  name: string;
  buildingId: number;
  rooms: {
    url: string;
  };
}

export interface IBuilding {
  id: number;
  buildingNo: string;
  abbreviation: string;
  name: string;
  street: string;
  postalCode: string;
  city: string;
  driveway: string;
  yearBuilt: string;
  description: string;
  buildingComplex: string;
  floors: {
    url: string;
  };
  gps: {
    latitude: number;
    longitude: number;
  };
}

export interface IBuildingWithDistance {
  building: IBuilding;
  distance: number;
}

export enum ConjectFMType {
  BUILDING = 'BUILDING',
  FLOOR = 'FLOOR',
  ROOM = 'ROOM'
}

const API_URL = environment.apiUrl;
const API_SERVICE_URL = `${API_URL}/conjectfm/v3`; 
const API_ROOMS = `${API_SERVICE_URL}/rooms`;
const API_BUILDINGS = `${API_SERVICE_URL}/buildings`;

@Injectable({
  providedIn: 'root'
})
export class ConjectFMService {
  private roomCache = new Set<IRoom>();

  constructor(
    private http: HttpClient
  ) { }

  public room(id: number, detailed = false): Observable<IRoom> {
    const filtered = Array.from(this.roomCache).filter(room => room.id === id);
    if (filtered.length > 0) {
      return of(filtered[0]);
    }
    return this.http.get(`${API_ROOMS}/${id}?detailed=${detailed}`).pipe(tap((room: IRoom) => {
      this.roomCache.add(room);
    }));
  }
  
  public rooms(roomIds: number[], detailed = false): Observable<IRoom[]> {
    return this.http.post<IRoom[]>(`${API_ROOMS}?detailed=${detailed}`, roomIds).pipe(tap((rooms: IRoom[]) => {
      rooms.forEach(room => this.roomCache.add(room));
    }));
  }

  public buildingsWithDistance(latitude: number, longitude: number, elevation: number = 0.0, threshold: number = 50.0): Observable<IBuildingWithDistance[]> {
    return this.http.get<IBuildingWithDistance[]>(`${API_BUILDINGS}/distance`, { params: new HttpParams().set('lat', latitude).set('lng', longitude).set('elevation', elevation).set('threshold', threshold) });
  }

  public buildings(): Observable<IBuilding[]> {
    return this.http.get<IBuilding[]>(`${API_BUILDINGS}`);
  }

  public floors(buildingId: number): Observable<IFloor[]> {
    return this.http.get<IFloor[]>(`${API_BUILDINGS}/${buildingId}/floors`);
  }

  public floorRooms(floorId: number, detailed = false): Observable<IRoom[]> {
    return this.http.get<IRoom[]>(`${API_BUILDINGS}/floors/${floorId}/rooms?detailed=${detailed}`);
  }
}
